import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
// Primereact components
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
// Helper functions
import {
  dateToParameter,
  dateToQueryString,
  isCurrentUserAdmin,
  sendQuery,
  validateTime,
  initLogger,
} from "common/Helpers";
// Generate download dialog
import download from "downloadjs";
// Redux
import { connect } from "react-redux";
import { updateUserId } from "actions/mobileActions";
import { setDriveSession } from "actions/sessionActions";
// Localization
import { useIntl } from "react-intl";
// Styling
import "./Style.scss";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
import AdditionalTimeRow from "./AdditionalTimeRow";
const logger = initLogger("drive_list_header");

const DriveListHeader = React.forwardRef((props, ref) => {
  const {
    currentUser,
    handleRefresh,
    drivers,
    updateUserId,
    setSelDate,
    selectedDate,
    selectedUser,
    selectedIndex,
    setDriveSession,
    handleGarageWSUpdate,
  } = props;
  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    updateValidations() {
      validateInputs();
    },
  }));

  const DEFAULT_START = null;
  const DEFAULT_END = null;
  const DEFAULT_PAUSE = null;

  const [garageStart, setGarageStart] = useState(DEFAULT_START);
  const [garageEnd, setGarageEnd] = useState(DEFAULT_END);
  const [pause, setPause] = useState(DEFAULT_PAUSE);
  const [dataId, setDataId] = useState(null);
  const [pending, setPending] = useState(false);
  const [reportPending, setReportPending] = useState(false);
  const [additionalTime, setAdditionalTime] = useState(null);

  const [blocked, setBlocked] = useState(false);
  const [checked, setChecked] = useState(false);
  const [remark, setRemark] = useState("");
  const [numChecked, setNumChecked] = useState(0);

  const [validations, setValidations] = useState({
    start: null,
    end: null,
    pause: null,
  });
  const [filterUser, setFilterUser] = useState(
    selectedUser?.personId ? selectedUser : currentUser
  );
  const [filterDate, setFilterDate] = useState(
    selectedDate ? new Date(selectedDate) : new Date()
  );

  //set selDate to avoid setting manuel entries on today's date
  const [amDum, setAmDum] = useState(false);
  if (!amDum) {
    setSelDate(filterDate);
    setAmDum(true);
  }

  const toast = useRef(null);
  const intl = useIntl();
  const isAdmin = isCurrentUserAdmin();

  const {
    DRIVES_HEADER_DAY,
    DRIVES_HEADER_GARAGE_START,
    DRIVES_HEADER_GARAGE_END,
    DRIVES_HEADER_NAME,
    DRIVES_HEADER_PAUSE,

    DRIVES_REFRESH,
    DRIVES_SAVE_TIMES,

    MONDAY_LONG,
    TUESDAY_LONG,
    WEDNESDAY_LONG, // AAAAAAAAAAAAAAAAAAAAAAAAAAAH
    THURSDAY_LONG,
    FRIDAY_LONG,
    SATURDAY_LONG,
    SUNDAY_LONG,
    DRIVES_PRINT_DAILY_REPORT,
    DRIVES_PRINT_TRANSPORT_REPORT,
    DRIVES_PRINT_MONTHLY_REPORT,

    DRIVERS_HEADER_BLOCKED,
    DRIVERS_HEADER_CHECKED,
    DRIVERS_HEADER_CONTROLLED,
    DRIVERS_HEADER_NEXT,
  } = MESSAGE_KEYS;

  const weekdays = [
    SUNDAY_LONG,
    MONDAY_LONG,
    TUESDAY_LONG,
    WEDNESDAY_LONG,
    THURSDAY_LONG,
    FRIDAY_LONG,
    SATURDAY_LONG,
  ];

  useEffect(() => {
    if (filterUser?.personId) {
      fetchDailyData(filterUser.personId, filterDate);
      updateUserId(filterUser.personId);
    }
  }, [filterUser, filterDate, updateUserId]);

  useEffect(() => {
    setNumChecked(
      drivers.filter((driver) => driver.timeChecked === true).length
    );
  }, [drivers]);

  const driverCheckTemplate = (user) => {
    return (
      <div className="flex align-items-center">
        <div>{user.alias}</div>

        {user.timeChecked && (
          <i className="pi pi-verified ml-1" style={{ color: "green" }} />
        )}
      </div>
    );
  };

  const fixWrongTime = (fixWrongTime) => {
    if (
      fixWrongTime === null ||
      typeof fixWrongTime === "undefined" ||
      fixWrongTime === ""
    ) {
      return null;
    }
    const [hours, minutes] = fixWrongTime.split(":");
    const hourText = parseInt(hours) > 24 ? "24" : hours;
    let minuteText = "";
    if (parseInt(hours) >= 24) {
      minuteText = "00";
    } else {
      minuteText = parseInt(minutes) > 59 ? "59" : minutes;
    }
    return `${hourText}:${minuteText}`;
  };

  const saveAdditionalTimeData = (payload, index) => {
    let oldGarageEnd = payload?.endtime;
    const isDayOverlapping = checkDayOverlapping(
      fixWrongTime(payload?.starttime),
      fixWrongTime(payload?.endtime)
    );
    if (isDayOverlapping) {
      oldGarageEnd = garageEnd;
    }

    let dateString = dateToQueryString(filterDate, false, "-");
    let endtime = null;
    if (isDayOverlapping) {
      endtime = "00:00";
    } else if (payload?.endtime) {
      endtime = fixWrongTime(payload?.endtime);
    }

    const dailyData = {
      dailyDriverReportId: payload?.dailyDriverReportId
        ? payload.dailyDriverReportId
        : null,
      appointmentId: null,
      personId: filterUser.personId,
      reportDate: dateString,
      closureDate: dateString,
      starttime: payload?.starttime ? fixWrongTime(payload.starttime) : null,
      endtime,
      pauseMin: payload?.pauseMin,
      totalTimeAppointmentsMin: 660,
      driverName: filterUser.fullname,
    };
    sendQuery(QUERIES.EDIT_DAILY_DRIVE, "post", dailyData, "text").then(
      (data) => {
        changeAdditionalTimeRow(index, "starttime", dailyData.starttime);
        changeAdditionalTimeRow(index, "endtime", dailyData.endtime);
        changeAdditionalTimeRow(
          index,
          "dailyDriverReportId",
          dailyData?.dailyDriverReportId
            ? dailyData.dailyDriverReportId
            : data?.id
        );
        if (isDayOverlapping) {
          addOverlappingDay(oldGarageEnd);
        }
      }
    );
  };

  /**
   *
   * 22:00 - 06:00 = overlapping
   * 23:15 - 23:00 = overlapping
   * 06:00 - 14:00 = not overlapping
   * @param {string} startTime
   * @param {string} endTime
   * @returns
   */
  const checkDayOverlapping = (startTime, endTime) => {
    if (
      endTime === "00:00" ||
      endTime === "24:00" ||
      endTime === "" ||
      typeof endTime === "undefined" ||
      endTime === null ||
      typeof startTime === "undefined" ||
      startTime === null ||
      startTime === ""
    ) {
      return false;
    }
    try {
      const [startHours, startMinutes] = startTime.split(":").map(Number);
      const [endHours, endMinutes] = endTime.split(":").map(Number);

      return (
        endHours < startHours ||
        (endHours === startHours && endMinutes < startMinutes)
      );
    } catch (lapException) {
      logger.error(lapException);
      return true;
    }
  };

  const addOverlappingDay = (oldGarageEnd) => {
    let newDate = new Date(filterDate);
    newDate.setDate(newDate.getDate() + 1); //next day
    let newDateString = dateToQueryString(newDate, false, "-");
    const newDailyData = {
      dailyDriverReportId: null,
      appointmentId: null,
      personId: filterUser.personId,
      reportDate: newDateString,
      closureDate: newDateString,
      starttime: "00:00",
      endtime: fixWrongTime(oldGarageEnd),
      pauseMin: 0,
      totalTimeAppointmentsMin: 660,
      driverName: filterUser.fullname,
    };

    sendQuery(QUERIES.EDIT_DAILY_DRIVE, "post", newDailyData, "text").then(
      () => {
        toast.current.show({
          severity: MESSAGE_SEVERITY.INFO,
          summary: intl.formatMessage({
            id: MESSAGE_KEYS.DRIVES_DAILY_ADDITIONAL_SAVE_SUCCESS,
          }),
        });
      },
      (error) => {
        logger.error(error);
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({
            id: MESSAGE_KEYS.DRIVES_DAILY_ADDITIONAL_SAVE_FAIL,
          }),
        });
      }
    );
  };

  const saveDailyData = (callback = undefined) => {
    validateInputs().then(() => {
      setPending(true);
      let oldGarageEnd = null;
      const isDayOverlapping = checkDayOverlapping(
        fixWrongTime(garageStart),
        fixWrongTime(garageEnd)
      );
      if (isDayOverlapping) {
        oldGarageEnd = garageEnd;
      }

      let dateString = dateToQueryString(filterDate, false, "-");
      const dailyData = {
        dailyDriverReportId: dataId,
        appointmentId: null,
        personId: filterUser.personId,
        reportDate: dateString,
        closureDate: dateString,
        starttime: garageStart ? fixWrongTime(garageStart) : null,
        endtime: isDayOverlapping
          ? "00:00"
          : garageEnd
          ? fixWrongTime(garageEnd)
          : null,
        pauseMin: pause,
        totalTimeAppointmentsMin: 660,
        driverName: filterUser.fullname,
        timeBlocked: blocked,
        timeChecked: checked,
        remark,
      };

      /* Zwischencheck um sicherzustellen, dass das Backend kein undefined bekommt => manchmal haben die primeng inputs undefined als value */
      if (typeof dailyData.endtime === "undefined") {
        dailyData.endtime = null;
      } else if (typeof dailyData.starttime === "undefined") {
        dailyData.starttime = null;
      }
      return sendQuery(
        QUERIES.EDIT_DAILY_DRIVE,
        "post",
        dailyData,
        "text"
      ).then(
        ({ message, id }) => {
          logger.info(message);
          toast.current.show({
            severity: MESSAGE_SEVERITY.SUCCESS,
            summary: intl.formatMessage(
              {
                id: MESSAGE_KEYS.DRIVES_DAILY_SAVE_SUCCESS,
              },
              { name: filterUser.fullname, date: dateString }
            ),
          });
          setDataId(id);
          setPending(false);
          setGarageStart(dailyData.starttime);
          setGarageEnd(dailyData.endtime);
          handleGarageWSUpdate(garageStart);
          if (additionalTime?.length > 0) {
            additionalTime.forEach((dataObj, index) => {
              saveAdditionalTimeData(dataObj, index);
            });
          }
          if (isDayOverlapping) {
            addOverlappingDay(oldGarageEnd);
          }
          setBlocked(dailyData.timeBlocked);
          setChecked(dailyData.timeChecked);
          setRemark(dailyData.remark);
          setSelDate(filterDate);
          if (callback !== undefined) {
            callback();
          }
        },
        (error) => {
          logger.error(error);
          toast.current.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: intl.formatMessage({
              id: MESSAGE_KEYS.DRIVES_DAILY_SAVE_FAIL,
            }),
          });
          setPending(false);
        }
      );
    });
  };

  const handleRefreshClick = () => {
    handleRefresh(filterUser?.personId);
    saveDailyData();
  };

  const validateInputs = () => {
    return new Promise((resolve) => {
      setValidations({
        start: validateTime(garageStart),
        end: validateTime(garageEnd),
        pause: pause !== null && pause !== undefined,
      });
      resolve();
    });
  };

  const generateReport = (personId) => {
    setReportPending(true);
    const params = dateToParameter(filterDate ? filterDate : new Date());
    sendQuery(
      `${QUERIES.GET_DRIVE_REPORT}${personId}&fromDate=${params[0]}`,
      "GET",
      null,
      "blob"
    )
      .then(
        (response) => {
          if (response) {
            download(
              response,
              `daily_report-${params[0]}-${params[1]}.pdf`,
              "application/pdf"
            );
          }
          setReportPending(false);
        },
        (error) => {
          logger.error(error);
          toast.current.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: intl.formatMessage({
              id: MESSAGE_KEYS.ERROR_DATA_FETCH,
            }),
          });
          setReportPending(false);
        }
      )
      .finally(() => {
        saveDailyData();
      });
  };

  const selectNextChecked = () => {
    const filteredDrivers = drivers.filter(
      (driver) =>
        driver.timeChecked === false || driver.personId === filterUser.personId
    );
    if (filteredDrivers.length > 1) {
      const filterIndex = filteredDrivers.findIndex(
        (driver) => driver.personId === filterUser.personId
      );
      if (filterIndex >= 0) {
        changeUser(
          filterIndex < filteredDrivers.length - 1
            ? filteredDrivers[filterIndex + 1]
            : filteredDrivers[0]
        );
      } else {
        changeUser(filteredDrivers[0]);
      }
    }
  };

  const generateMonthlyReport = (personId) => {
    setReportPending(true);
    const params = dateToParameter(filterDate ?? new Date());

    if (personId === null || params[0] === null) {
      logger.error(
        "Could not generate monthly report: either personId or fromDate is null"
      );
      toast.current.show({
        severity: MESSAGE_SEVERITY.ERROR,
        summary: intl.formatMessage({
          id: MESSAGE_KEYS.DRIVER_GENERATE_MONTHLY_REPORT_FAIL,
        }),
      });
      return;
    }

    sendQuery(
      `${QUERIES.GET_MONTHLY_REPORT}personId=${personId}&fromDate=${params[0]}`,
      "GET",
      null,
      "blob"
    ).then(
      (response) => {
        if (response) {
          download(
            response,
            `monthly_report-${personId}-${params[0]}.pdf`,
            "application/pdf"
          );
        }
        setReportPending(false);
      },
      (error) => {
        logger.error(error);
        toast.current.show({
          severity: MESSAGE_SEVERITY.ERROR,
          summary: intl.formatMessage({
            id: MESSAGE_KEYS.DRIVER_GENERATE_MONTHLY_REPORT_FAIL,
          }),
        });
        setReportPending(false);
      }
    );
  };
  const generateTransportReport = (personId) => {
    if (
      personId == null ||
      props?.allAppointments == null ||
      props?.allAppointments?.length === 0
    ) {
      toast.current.show({
        severity: MESSAGE_SEVERITY.WARNING,
        summary: intl.formatMessage({
          id: MESSAGE_KEYS.DRIVER_GENERATE_TRANSPORT_REPORT_FAIL,
        }),
      });
      return;
    }
    let appointmentsIds = getFormattedAppointmentIds(props.allAppointments);

    if (appointmentsIds !== "") {
      setReportPending(true);
      sendQuery(
        `${QUERIES.GET_DELIVERY_NOTE}?appointmentIds=${appointmentsIds}&personId=${personId}`,
        "GET",
        null,
        "blob"
      ).then(
        (response) => {
          if (response) {
            download(
              response,
              `transport_report-${personId}.pdf`,
              "application/pdf"
            );
          }
          setReportPending(false);
        },
        (error) => {
          logger.error(error);
          toast.current.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: intl.formatMessage({
              id: MESSAGE_KEYS.DRIVER_GENERATE_TRANSPORT_REPORT_FAIL,
            }),
          });
          setReportPending(false);
        }
      );
    } else {
      toast.current.show({
        severity: MESSAGE_SEVERITY.WARNING,
        summary: intl.formatMessage({
          id: MESSAGE_KEYS.DRIVER_GENERATE_TRANSPORT_REPORT_FAIL,
        }),
      });
    }
  };

  const getFormattedAppointmentIds = (appointments) => {
    let formattedIds = "";
    appointments.forEach((appointment) => {
      if (appointment.appointment.driverEntry === null) {
        formattedIds += appointment.appointment.appointmentId + ",";
      }
    });
    if (formattedIds !== "") {
      formattedIds = formattedIds.slice(0, -1);
    }

    return formattedIds;
  };

  const fetchDailyData = (personId, date) => {
    setPending(true);
    const params = dateToParameter(date ? date : new Date());
    sendQuery(
      `${QUERIES.GET_DAILY_DRIVE}${personId}&fromDate=${params[0]}`,
      "GET"
    ).then(
      (response) => {
        if (response.length > 0) {
          const {
            starttime,
            endtime,
            pauseMin,
            dailyDriverReportId,
            timeBlocked,
            timeChecked,
            remark,
          } = response[0];
          setGarageStart(starttime);
          setGarageEnd(endtime);
          setPause(pauseMin);
          setDataId(dailyDriverReportId);
          setBlocked(timeBlocked);
          setChecked(timeChecked);
          setRemark(remark);

          if (response.length > 1) {
            delete response[0];
            const filteredAdditionalTime = response.filter((n) => n !== null);
            setAdditionalTime(filteredAdditionalTime);
          }
        } else {
          setGarageStart(DEFAULT_START);
          setGarageEnd(DEFAULT_END);
          setPause(DEFAULT_PAUSE);
          setDataId(null);
          setAdditionalTime(null);
          setBlocked(false);
          setChecked(false);
          setRemark("");
        }
        setPending(false);
      },
      (error) => {
        logger.error(error);
        setGarageStart(DEFAULT_START);
        setGarageEnd(DEFAULT_END);
        setPause(DEFAULT_PAUSE);
        setDataId(null);
        setPending(false);
      }
    );
  };

  const renderDateSelection = () => {
    return (
      <div className="calendar-button-container">
        <Button
          onClick={() => {
            let todaysDate = new Date();
            setFilterDate(todaysDate);
            setSelDate(todaysDate);
            fetchDailyData(filterUser.personId, todaysDate);
            handleRefresh(filterUser?.personId, todaysDate);
            setDriveSession({
              user: filterUser,
              date: todaysDate,
              index: selectedIndex,
            });
          }}
          icon="pi pi-calendar"
          style={{
            backgroundColor:
              filterDate.getDate() === new Date().getDate() &&
              filterDate.getMonth() === new Date().getMonth()
                ? "green"
                : "red",
            border: "none",
          }}
          disabled={
            filterDate.getDate() === new Date().getDate() &&
            filterDate.getMonth() === new Date().getMonth()
          }
        />
        <Calendar
          value={filterDate}
          onChange={(e) => {
            setAdditionalTime(null);
            setFilterDate(e.value);
            setSelDate(e.value);
            fetchDailyData(filterUser.personId, e.value);
            handleRefresh(filterUser?.personId, e.value);
            setDriveSession({
              user: filterUser,
              date: e.value,
              index: selectedIndex,
            });
          }}
          dateFormat="dd/mm/yy"
          selectOtherMonths={true}
          className="time_input"
        />
      </div>
    );
  };

  const changeUser = (user) => {
    setFilterUser(user);
    if (user?.personId) {
      fetchDailyData(user.personId, filterDate);
      handleRefresh(user.personId);
      updateUserId(user.personId);
      setDriveSession({
        user: user,
        date: filterDate,
        index: selectedIndex ?? 0,
      });
    }
  };

  const renderUserSelection = () => {
    if (isAdmin) {
      return (
        <Dropdown
          id="drivelist_header_user_filter"
          placeholder={filterUser?.alias}
          optionLabel="alias"
          value={filterUser}
          options={drivers}
          onChange={(e) => {
            changeUser(e.value);
          }}
          style={{ height: "40px", lineHeight: "20px" }}
          disabled={!drivers || drivers.length < 1}
          appendTo={document.body}
          filter
          filterBy="alias"
          filterInputAutoFocus={true}
          itemTemplate={driverCheckTemplate}
        />
      );
    } else {
      return <div>{currentUser?.fullname}</div>;
    }
  };

  const renderUserDateSelectionRow = (
    <div>
      <div>
        <div className="font-bold">
          {intl.formatMessage({ id: DRIVES_HEADER_NAME })}:
        </div>
        <div>{renderUserSelection()}</div>
      </div>
      {isAdmin && (
        <div>
          <div className="font-bold">
            {intl.formatMessage(
              { id: DRIVERS_HEADER_CHECKED },
              { controlled: numChecked, total: drivers.length }
            )}
          </div>
          <div>
            <Button
              label={intl.formatMessage({ id: DRIVERS_HEADER_NEXT })}
              onClick={() => saveDailyData(selectNextChecked)}
              disabled={numChecked === drivers.length}
              loading={pending}
            ></Button>
          </div>
        </div>
      )}
      <div>
        <div className="flex align-items-start gap-1">
          <div className="font-bold">
            {intl.formatMessage({ id: DRIVES_HEADER_DAY })}:
          </div>
          <div className="font-bold">
            {intl.formatMessage({
              id: weekdays[
                filterDate?.getDay() ? filterDate.getDay() : new Date().getDay()
              ],
            })}
          </div>
        </div>
        <div>{renderDateSelection()}</div>
      </div>
      {isAdmin && (
        <div className="flex flex-column align-items-start gap-1">
          <div className="flex align-items-center">
            <Checkbox
              inputId="blocked"
              checked={blocked}
              onChange={(e) => setBlocked(e.checked)}
            />
            <label htmlFor="blocked" className="ml-2">
              {intl.formatMessage({ id: DRIVERS_HEADER_BLOCKED })}
            </label>
          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="checked"
              checked={checked}
              onChange={(e) => setChecked(e.checked)}
            />
            <label htmlFor="checked" className="ml-2">
              {intl.formatMessage({ id: DRIVERS_HEADER_CONTROLLED })}
            </label>
          </div>
        </div>
      )}
    </div>
  );

  const renderButtonsRow = (
    <div
      className={
        "flex justify-content-between align-items-center report-buttons-container"
      }
    >
      <Button
        loading={pending}
        onClick={handleRefreshClick}
        icon="pi pi-refresh"
        label={intl.formatMessage({ id: DRIVES_REFRESH })}
      />

      <Button
        loading={pending || reportPending}
        onClick={() => generateReport(filterUser?.personId)}
        icon="pi pi-file-pdf"
        label={intl.formatMessage({ id: DRIVES_PRINT_DAILY_REPORT })}
      />

      <Button
        loading={pending || reportPending}
        label={intl.formatMessage({ id: DRIVES_PRINT_MONTHLY_REPORT })}
        icon="pi pi-file-pdf"
        onClick={() => generateMonthlyReport(filterUser?.personId)}
      />
      <Button
        loading={pending || reportPending}
        onClick={() => generateTransportReport(filterUser?.personId)}
        icon="pi pi-file-pdf"
        disabled={props.allAppointments.length === 0 ? true : false}
        label={intl.formatMessage({ id: DRIVES_PRINT_TRANSPORT_REPORT })}
      />

      <Button
        loading={pending}
        onClick={() => saveDailyData()}
        icon="pi pi-save"
        label={intl.formatMessage({ id: DRIVES_SAVE_TIMES })}
        className="p-button-success"
        disabled={blocked && !isAdmin}
      />
    </div>
  );

  const addAdditionalRow = () => {
    let newRow = {
      dailyDriverReportId: null,
      starttime: null,
      endtime: null,
      pauseMin: null,
    };
    let newTimeArray = additionalTime ?? [];
    newTimeArray.push(newRow);
    setAdditionalTime(newTimeArray);
  };

  const renderFirstTimeRow = (
    <div>
      <div>
        <div className="font-bold">
          {intl.formatMessage({
            id: DRIVES_HEADER_GARAGE_START,
          })}
          :
        </div>
        <div>
          <InputMask
            mask="99:99"
            slotChar="hh:mm"
            value={garageStart}
            onChange={(event) => setGarageStart(event.value)}
            style={{
              outline: validations.start === false ? "3px solid red" : "",
            }}
            className="time_input"
            /*             onComplete={(e) => saveDailyData()} */
            disabled={blocked && !isAdmin}
          />
        </div>
      </div>
      <div>
        <div className="font-bold">
          {intl.formatMessage({
            id: DRIVES_HEADER_GARAGE_END,
          })}
          :
        </div>
        <div>
          <InputMask
            mask="99:99"
            slotChar="hh:mm"
            value={garageEnd}
            onChange={(event) => setGarageEnd(event.value)}
            style={{
              outline: validations.end === false ? "3px solid red" : "",
            }}
            className="time_input"
            disabled={blocked && !isAdmin}
          />
        </div>
      </div>
      <div>
        <div className="font-bold">
          {intl.formatMessage({
            id: DRIVES_HEADER_PAUSE,
          })}
          :
        </div>
        <div style={{ display: "contents" }} className="flex gap-3">
          <InputNumber
            value={pause}
            onValueChange={(event) => setPause(event.target.value)}
            mode="decimal"
            inputStyle={{
              outline: validations.pause === false ? "3px solid red" : "",
            }}
            className="time_input"
            disabled={blocked && !isAdmin}
          />
          <Button
            icon="pi pi-plus"
            aria-label="Add"
            style={{
              visibility: additionalTime?.length > 0 ? "hidden" : "visible",
            }}
            onClick={addAdditionalRow}
            className="p-button-rounded p-button-primary"
            disabled={blocked && !isAdmin}
          />
        </div>
      </div>
      <div className="remark-input">
        {isAdmin ? (
          <InputTextarea
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        ) : (
          <div>{remark}</div>
        )}
      </div>
    </div>
  );

  const renderAdditionalTimeRows = () => {
    const rows = [];
    additionalTime.forEach((timeObj, index) => {
      rows.push(
        <AdditionalTimeRow
          garageStart={timeObj?.starttime}
          garageEnd={timeObj?.endtime}
          pause={timeObj?.pauseMin}
          showButton={index + 1 === additionalTime.length}
          changeValue={changeAdditionalTimeRow}
          addRow={addAdditionalRow}
          index={index}
          key={index}
          deleteRow={deleteRow}
          blocked={blocked && !isAdmin}
        />
      );
    });
    return rows;
  };

  const changeAdditionalTimeRow = (index, attr, value) => {
    let newAddTime = [...additionalTime];
    newAddTime[index][attr] = value;
    setAdditionalTime(newAddTime);
  };

  const deleteRow = (index) => {
    if (additionalTime[index].dailyDriverReportId) {
      sendQuery(
        `${QUERIES.DELETE_DAILY_DRIVE}${additionalTime[index].dailyDriverReportId}`,
        "delete"
      ).then(
        () => {},
        (error) => {
          logger.error(error);
          toast.current.show({
            severity: MESSAGE_SEVERITY.ERROR,
            summary: error,
          });
          return; //dont remove the row if the backend throws an error
        }
      );
    }
    const newadditionalTime = [...additionalTime];
    delete newadditionalTime[index];
    const filteredAdditionalTime = newadditionalTime.filter((n) => n !== null); //using delete, it only replaces the object with a null value, so lets filter it out
    setAdditionalTime(filteredAdditionalTime);
  };

  return (
    <div ref={ref}>
      <Toast ref={toast} />
      <div
        className={`driver_header_base ${
          blocked ? "blocked" : ""
        } mb-4 p-2 driver_name_day`}
      >
        <div className="input_container">
          {renderUserDateSelectionRow}
          {renderFirstTimeRow}
          {additionalTime ? renderAdditionalTimeRows() : ""}
        </div>
        {renderButtonsRow}
      </div>
    </div>
  );
});

const mapStateToProps = (state) => {
  try {
    const {
      session: { driveSession },
    } = state;
    return {
      selectedUser: driveSession.user,
      selectedDate: driveSession.date,
      selectedIndex: driveSession.index,
    };
  } catch (e) {
    return {
      selectedUser: null,
      selectedDate: null,
      selectedIndex: 0,
    };
  }
};

export default connect(
  mapStateToProps,
  { updateUserId, setDriveSession },
  null,
  {
    forwardRef: true,
  }
)(DriveListHeader);
