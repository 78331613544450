/**
 * This component renders the base layout of the right hand side of the bills view.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// Redux
import { unsetTransferParams } from "actions";
import { setChangePending, setBillSession } from "actions/sessionActions";
import { connect } from "react-redux";
// Responsive
import { isDesktop, withOrientationChange } from "react-device-detect";
// PrimeReact Components
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
// Custom Components
import { BillEditLayout } from "./index";
import { PaymentEditor, FilearchiveEditor } from "components/common";
// Static values
import {
  MESSAGE_KEYS,
  MESSAGE_SEVERITY,
  QUERIES,
} from "assets/staticData/enums";
// Helper classes
import { sendQuery, initLogger, equalObjects } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Logging
const logger = initLogger("bill_edit_view");

class BillEditView extends React.Component {
  state = {
    billFetchPending: false,
    billError: null,
    warningFetchPending: false,
    displayedBill: null,
    paymentsList: [],
    pdfFetchPending: false,
    selectedFile: null,
    fileUploadPending: false,
    sessionDataAvailable: false,

    totalPaid: 0.0,
    totalAmount: 0.0,

    paymentDialogVisible: false,

    titleAppendix: "",
  };

  componentDidMount = () => {
    const { billSession } = this.props;
    // Create ref to edit view for scroll to functionality.
    this.editViewRef = React.createRef();
    this.uploadRef = React.createRef();
    // Add drag/drop events to file dropzone.
    let dropPoints = document.getElementsByClassName("p-fileupload-content");
    if (dropPoints && dropPoints.length > 0) {
      dropPoints[0].addEventListener("dragenter", (e) =>
        dropPoints[0].classList.add("files_drag_active")
      );
      dropPoints[0].addEventListener("dragend", (e) =>
        dropPoints[0].classList.remove("files_drag_active")
      );
      dropPoints[0].addEventListener("drop", (e) =>
        dropPoints[0].classList.remove("files_drag_active")
      );
    }
    // Load session data.
    if (billSession && billSession.selectedSessionBill) {
      const { selectedSessionBill } = billSession;
      this.setState(
        {
          titleAppendix:
            selectedSessionBill &&
            selectedSessionBill.customer &&
            selectedSessionBill.customer.healthInsuranceNumber
              ? selectedSessionBill.customer.healthInsuranceNumber
              : "",
          displayedBill: selectedSessionBill,
          billFetchPending: false,
          sessionDataAvailable: true,
        },
        () => {
          const { transactionDetails, transactionPayments } =
            selectedSessionBill;
          this.updateTotalPaid(transactionPayments);
          this.updateParentTotal(transactionDetails);
        }
      );
    }
  };

  /**
   * Checks if the new bill button was clicked or a bill was selected in the bill view table.
   * If a different bill was selected, this function will fetch the selected bill's data from the backend and store if in the state's displayedBill object.
   * If the new bill button was clicked, the state's displayedBill object will be filled with empty values.
   *
   * @param {Object} prevProps
   */
  componentDidUpdate = (prevProps, prevState) => {
    const { selectedBill, transferId, unsetTransferParams, billSession } =
      this.props;
    const { displayedBill = null } = this.state;
    // Check if the search table selection was changed.
    if (selectedBill !== prevProps.selectedBill) {
      // Selected bill is available, session data not. Fetch bill from BE.
      if (selectedBill > 0 && !this.state.sessionDataAvailable) {
        this.setState({ billFetchPending: true, billError: null }, () =>
          this.fetchBill(selectedBill)
        );
      } else {
        // Transfer ID is set, fetch bill corresponding to ID and clear transfer params.
        if (transferId) {
          if (typeof transferId === "number") {
            // Received bill id, call BE to fetch data.
            this.setState(
              {
                billFetchPending: true,
                billError: null,
                sessionDataAvailable: false,
              },
              () => {
                this.fetchBill(transferId);
              }
            );
          } else {
            // Received object, init with received data.
            this.setState({
              titleAppendix:
                transferId &&
                transferId.customer &&
                transferId.customer.healthInsuranceNumber
                  ? transferId.customer.healthInsuranceNumber
                  : "",
              displayedBill: { ...transferId },
              paymentsList: [],
            });
          }
          unsetTransferParams();
        } else {
          // Load available session data if available, set empty bill with transaction ID for new bill else.
          const { selectedSessionBill } = billSession;
          this.setState(
            {
              titleAppendix:
                selectedSessionBill &&
                selectedSessionBill.customer &&
                selectedSessionBill.customer.healthInsuranceNumber
                  ? selectedSessionBill.customer.healthInsuranceNumber
                  : "",
              displayedBill: this.state.sessionDataAvailable
                ? selectedSessionBill
                : { transactionId: selectedBill },
              billFetchPending: false,
              sessionDataAvailable: false,
              paymentsList: this.state.sessionDataAvailable
                ? selectedSessionBill.transactionPayments
                : [],
            },
            () => this.handleScrollTo()
          );
        }
      }
    }
    // Update the total paid value if the displayed bill was changed.
    if (
      prevState === undefined ||
      prevState === null ||
      !equalObjects(displayedBill, prevState.displayedBill, "transactionId")
    ) {
      if (displayedBill) {
        const { transactionDetails, transactionPayments } = displayedBill;
        this.updateTotalPaid(transactionPayments ? transactionPayments : []);
        this.updateParentTotal(transactionDetails ? transactionDetails : []);
      } else {
        this.updateTotalPaid([]);
        this.updateParentTotal([]);
      }
    }
  };

  updateParentTotal = (newPositions) => {
    let totalAmount = 0.0;
    try {
      if (newPositions && newPositions.length > 0) {
        newPositions.forEach((payment) => {
          const { active, amount, price, totalPrice } = payment;
          if (active) {
            if (totalPrice) {
              totalAmount += totalPrice;
            } else {
              totalAmount += (amount ? amount : 0) * (price ? price : 0);
            }
          }
          //totalAmount += active ? (totalPrice ? totalPrice : (amount ? amount : 0) * (price ? price : 0)) : 0.0;
        });
      }
    } catch (totalException) {
      logger.warn(
        "Exception on update total amount",
        totalException,
        newPositions
      );
    } finally {
      this.setState({
        totalAmount,
      });
    }
  };

  /**
   * Calculates the total amount of the supplied payments list.
   * Only payments where active === true are considered.
   *
   * @param {Array<Object>} newPayments
   */
  updateTotalPaid = (newPayments) => {
    let totalPaid = 0.0;
    try {
      if (newPayments && newPayments.length > 0) {
        newPayments.forEach((payment) => {
          const { active, amount } = payment;
          totalPaid += active && amount ? amount : 0.0;
        });
      }
    } catch (totalException) {
      logger.warn(
        "Exception on update total paid",
        totalException,
        newPayments
      );
    } finally {
      this.setState({
        totalPaid,
      });
    }
  };

  handleSessionUpdate = (newBill = null) => {
    const { billSession, setBillSession } = this.props;
    setBillSession({
      ...billSession,
      selectedSessionBill: { ...newBill },
    });
  };

  mapPaymentsForCustomer = (payments) => {
    if (payments) {
      payments.forEach((payment) => {
        payment.invoiceCustomer = null;
      });
      return payments;
    } else {
      return [];
    }
  };

  handlePaymentUpdate = (newPaymentsList) => {
    const newDisplayedBill = { ...this.state.displayedBill };
    let paymentsList = [...newPaymentsList];
    newDisplayedBill.transactionPayments =
      this.mapPaymentsForCustomer(paymentsList);
    this.setState(
      {
        paymentsList,
        paymentDialogVisible: false,
        displayedBill: newDisplayedBill,
      },
      () => {
        this.toast.show({
          severity: MESSAGE_SEVERITY.SUCCESS,
          summary: this.props.intl.formatMessage({
            id: MESSAGE_KEYS.PAYMENT_SAVE_SUCCESS_MESSAGE,
          }),
        });
        this.updateTotalPaid(paymentsList);
        this.props.handleParentUpdate(this.props.selectedBill);
      }
    );
  };

  /**
   * Gets called when the user clicks on the delete button of the address table. Will attempt to remove the payment address from the state.
   *
   * @param {Number} id
   */
  handlePaymentRemove = (id) => {
    let paymentsList = [...this.state.paymentsList];
    let deleteIndex = paymentsList.findIndex((payment) => {
      return payment.paymentId === id;
    });
    if (deleteIndex >= 0) {
      paymentsList.splice(deleteIndex, 1);
      const newDisplayedBill = { ...this.state.displayedBill };
      newDisplayedBill.transactionPayments =
        this.mapPaymentsForCustomer(paymentsList);
      this.setState({ paymentsList, displayedBill: newDisplayedBill });
      this.toast.show({
        severity: MESSAGE_SEVERITY.SUCCESS,
        summary: this.props.intl.formatMessage({
          id: MESSAGE_KEYS.PAYMENT_DELETE_SUCCESS_MESSAGE,
        }),
      });
      this.updateTotalPaid(paymentsList);
      this.props.handleParentUpdate(this.props.selectedBill);
    }
  };

  /**
   * This function is used to scroll to the edit view.
   * Only available if the edit view ref is set.
   */
  handleScrollTo = () => {
    if (this.editViewRef && !isDesktop) {
      this.editViewRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };

  handleParentUpdate = (newId, billNumber) => {
    const { setChangePending, handleParentUpdate } = this.props;
    this.setState(
      {
        displayedBill: {
          ...this.state.displayedBill,
          transactionId: newId,
        },
      },
      () => {
        // DOM manipulation to get upload button.
        let uploadButton = document.querySelector(
          ".p-fileupload-buttonbar > button:nth-child(2)"
        );
        try {
          logger.info("BTN", uploadButton, this.state.displayedBill);
          uploadButton.click();
        } catch (clickException) {
          logger.warn(clickException);
        }
      }
    );
    this.fetchBill(newId);
    setChangePending(false);
    handleParentUpdate(newId);
  };

  /**
   * Attempts to fetch bill data from the backend.
   *
   * @returns {Promise<BillData|String>} - The bill data on success, an error code else.
   */
  fetchBill = (newId = null) => {
    try {
      const { selectedBill } = this.props;
      return sendQuery(
        `${QUERIES.GET_BILL_BY_ID}${newId ? newId : selectedBill}`,
        "GET",
        null
      ).then(
        (response) => {
          this.setState(
            {
              displayedBill: response,
              paymentsList: response.transactionPayments,
              billFetchPending: false,
            },
            () => {
              this.handleSessionUpdate(response);
              this.handleScrollTo();
            }
          );
        },
        (error) => {
          this.setState({
            displayedBill: null,
            paymentsList: [],
            billFetchPending: false,
            billError: error,
          });
          this.handleSessionUpdate();
        }
      );
    } catch (fetchException) {
      this.setState(
        {
          displayedBill: null,
          billFetchPending: false,
          billError: fetchException.message,
        },
        () => this.handleSessionUpdate()
      );
    }
  };

  /**
   * Returns the bill edit layout component if the bill fetch request is done.
   * Returns an error message if an error occurred during the data transfer.
   * Will return a loading animation while the request is pending.
   *
   * @returns {JSX.Element}
   */
  renderBillInputs = () => {
    const { billFetchPending, billError, displayedBill, totalPaid } =
      this.state;
    if (billFetchPending) {
      return (
        <div>
          <ProgressSpinner />
        </div>
      );
    } else {
      if (billError) {
        return (
          <div>
            {typeof billError === "string" ? billError : billError.message}
          </div>
        );
      } else {
        return (
          <div>
            <BillEditLayout
              selectedBill={displayedBill}
              handleParentUpdate={this.handleParentUpdate}
              totalPaid={totalPaid}
              updateParentTotal={this.updateParentTotal}
              handleCustomerSelection={(personId, healthInsuranceNumber) => {
                this.props.handleCustomerSelection(personId);
                this.setState({
                  titleAppendix: healthInsuranceNumber
                    ? healthInsuranceNumber
                    : "",
                });
              }}
            />
          </div>
        );
      }
    }
  };

  /**
   * Renders the payments- & file-panels.
   *
   * @returns {Array[JSX.Element]} The payments- & file-panels with their respective contents.
   */
  renderSideComponents = () => {
    const { intl, selectedBill } = this.props;
    const { CUSTOMER_PAYMENT_TITLE_LABEL, BILLS_FILES_TITLE } = MESSAGE_KEYS;
    const { paymentsList, displayedBill, totalAmount, billFetchPending } =
      this.state;

    return [
      <Panel
        header={intl.formatMessage({ id: CUSTOMER_PAYMENT_TITLE_LABEL })}
        key="bill_payments_panel"
      >
        <PaymentEditor
          invoiceList={paymentsList}
          fetchPending={billFetchPending}
          handleParentDelete={this.handlePaymentRemove}
          handleParentUpdate={this.handlePaymentUpdate}
          disabled={!selectedBill || selectedBill < 0}
          selectedBill={selectedBill}
          selectedCustomer={
            displayedBill && displayedBill.customer
              ? displayedBill.customer.personId
              : null
          }
          totalAmount={totalAmount}
        />
      </Panel>,
      <Panel
        header={intl.formatMessage({ id: BILLS_FILES_TITLE })}
        key="bill_file_panel"
      >
        <FilearchiveEditor
          value={displayedBill}
          idLabel="transactionId"
          isBill
          updateParent={(newFileList) => {
            let newBillData = {
              ...displayedBill,
              filearchive: [...newFileList],
            };
            this.setState(
              {
                displayedBill: { ...newBillData },
              },
              () => {
                this.handleSessionUpdate(newBillData);
              }
            );
          }}
        />
      </Panel>,
    ];
  };

  /**
   * Renders a basic 3 column layout for the bill data-, bills- & payments-components when a bill is selected.
   * Will return a simple card component with a "Select bill" message else.
   *
   * @returns {JSX.Element}
   */
  renderContent = () => {
    const { selectedBill, intl, isLandscape, billSession } = this.props;
    const { BILLS_SELECT_BILL_LABEL, BILLS_TITLE_LABEL } = MESSAGE_KEYS;
    const { displayedBill } = this.state;
    const notResponsive = isDesktop || isLandscape;
    if (selectedBill || billSession?.selectedSessionBill) {
      let header = intl.formatMessage({ id: BILLS_TITLE_LABEL });
      if (notResponsive) {
        const { titleAppendix } = this.state;
        const title = displayedBill?.customer
          ? displayedBill.customer.healthInsuranceNumber
          : titleAppendix;
        header = (
          <div className="flex flex-row">
            {intl.formatMessage({ id: BILLS_TITLE_LABEL })}{" "}
            {titleAppendix ? (
              <span className="font-bold ml-2">{title}</span>
            ) : (
              <></>
            )}
          </div>
        );
      }
      return (
        <div className="grid">
          <div key="bill_row" className={`col-${notResponsive ? "7" : "12"}`}>
            <Panel header={header}>{this.renderBillInputs()}</Panel>
          </div>
          <div
            key="payment_row"
            className={`col-${notResponsive ? "5" : "12"}`}
          >
            {this.renderSideComponents()}
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-12">
          <Card>
            <strong>
              {intl.formatMessage({
                id: BILLS_SELECT_BILL_LABEL,
              })}
            </strong>
          </Card>
        </div>
      );
    }
  };

  render = () => {
    return (
      <div ref={this.editViewRef}>
        <Toast ref={(el) => (this.toast = el)}></Toast>
        {this.renderContent()}
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  try {
    const {
      application: { routerParams = null },
      session: { billSession = null },
      authentication: { currentUser = null },
    } = state;
    return {
      billSession,
      currentUser,
      transferId: routerParams,
    };
  } catch (mapException) {
    logger.error("Exception on mapStateToProps", mapException);
    return {
      billSession: null,
      currentUser: null,
      transferId: null,
    };
  }
};

export default connect(mapStateToProps, {
  setChangePending,
  setBillSession,
  unsetTransferParams,
})(injectIntl(withOrientationChange(BillEditView)));
